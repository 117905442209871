import { COUNTRY } from '@const/index';
import { Router } from 'next/router';
import React from 'react';
import { getReplaceParams, parseString } from './misc';
import { getMSiteHost, getPageImages } from './resource';

const host = getMSiteHost();

const SchemaLogo = ({
  id: `${host}/assets/image/seabank-logo.png`,
  ph: `${host}/assets/image/seabank-logo.png`,
  sg: `${host}/assets/image/maribank-logo.png`,
} as Record<string, string>)[COUNTRY];

const bankName = ({
  ph: 'SeaBank Philippines',
  id: 'SeaBank Indonesia',
} as Record<string, string>)[COUNTRY];
const withOrganisationSchema = () => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: bankName,
  url: host,
  logo: SchemaLogo,
});

const withBreadcrumbSchema = (breadcrumb: BreadcrumbItem[] = []) => {
  const list = breadcrumb.map((item, index) => ({
    '@type': 'ListItem',
    position: index + 2,
    name: item.name,
    item: `${host}${item.path}`,
  }));
  list.unshift({
    '@type': 'ListItem',
    position: 1,
    name: bankName,
    item: host,
  });
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: list,
  };
};

const withFAQSchema = (faq: question[] = []) => {
  const list = faq.map((item) => ({
    '@type': 'Question',
    name: item.title,
    acceptedAnswer: {
      '@type': 'Answer',
      text: item.content,
    },
  }));
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: list,
  };
};
const withHowToSchema = (title: string, steps: Step[] = []) => {
  const list = steps.map((item) => ({
    '@type': 'HowToStep',
    name: item.title,
    text: item.content,
    image: getPageImages(item.image),
  }));
  return {
    '@context': 'https://schema.org',
    '@type': 'HowTo',
    name: title,
    step: list,
  };
};

const withScript = (schema: any) => (
  <script
    type="application/ld+json"
    key={schema?.['@type']}
    dangerouslySetInnerHTML={{
      __html: JSON.stringify(schema),
    }}
  />
);

const nomarlizeBreadcrumb = (
  route: Router,
  props: PageTmpl | LeftNavPageTemplate,
) => {
  const replaceParam = getReplaceParams(route, props);
  const breadcrumb = [...(props.breadcrumb || [])];
  if (['/info', '/branch-product'].includes(route.pathname)) {
    breadcrumb.pop();
  }
  if (!replaceParam) {
    return breadcrumb;
  }

  const ret = (breadcrumb || []).map((item) => ({
    name: parseString(item.name, replaceParam),
    path: parseString(item.path, replaceParam),
  }));

  // category动态路由特殊处理
  // 1. url中不同时包含L1 category和L2 category时，会导致末尾的breadcrumb的name为空，需将其移除
  // 2. position: 3的breadcrumb的path部分只显示L1 category
  if (route.pathname === '/help-center/[[...category]]') {
    return ret
      .filter((item) => item.name)
      .map((item, index) => {
        if (index === 1) {
          return {
            name: item.name,
            path: item.path.split('/').slice(0, 3).join('/'),
          };
        } else {
          return item;
        }
      });
  }

  return ret;
};

export const getSchemaNodes = ({
  route,
  props,
  questions,
}: {
  route: Router;
  props: PageTmpl;
  questions?: question[];
}) => {
  const schemas = [];
  if (route.pathname === '/') {
    schemas.push(withOrganisationSchema());
  } else if (props.breadcrumb) {
    const resolvedBreadcrumb = nomarlizeBreadcrumb(route, props);
    schemas.push(withBreadcrumbSchema(resolvedBreadcrumb));
  }

  if (questions?.length) {
    if (route.pathname !== '/help-center/search') {
      // search页面不需要FAQ schema
      schemas.push(withFAQSchema(questions));
    }
  }

  if (route.pathname === '/apply') {
    const guiding = props.content?.Guiding;
    schemas.push(
      withHowToSchema(guiding?.title, props.content?.Guiding?.steps),
    );
  }

  return schemas.map((item) => withScript(item));
};
