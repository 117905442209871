import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './index.module.less';
import MenuSvg from '@/assets/svg/menu.svg';
import CloseSvg from '@/assets/svg/close.svg';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  disableMobileScrolling,
  enableMobileScrolling,
  formatRouterPath,
} from '@/utils/misc';
import { Logo } from '@const/index';
import cn from 'classnames';
import { BrowserObject, detectBrowser } from '@/utils/browser';

const MenuItem: React.FC<{
  item: NavItem;
  isActive?: boolean;
  onClick?: () => void;
}> = ({ item, isActive, onClick }) =>
  item.link ? (
    <Link href={formatRouterPath(item.link)}>
      <div
        className={cn({ [styles.activeMenuItem]: isActive }, styles.menuItem)}
      >
        <span onClick={onClick}>{item.text}</span>
      </div>
    </Link>
  ) : (
    <div className={cn({ [styles.activeMenuItem]: isActive }, styles.menuItem)}>
      <span>{item.text}</span>
    </div>
  );

interface IProps {
  nav: NavItem[];
  navButton?: NavItem;
}

function isActive(path: string, menuItem: NavItem) {
  if (menuItem.link === path) {
    return true;
  }
  if (menuItem.children) {
    return menuItem.children.some((item) => item.link === path);
  }
  if (Array.isArray(menuItem.hiddenChildren)) {
    return menuItem.hiddenChildren.some((item) => item.link === path);
  }
  return false;
}

function Navigator({ nav, navButton }: IProps) {
  const router = useRouter();
  const [menuVisible, setMenuVisible] = useState(false);
  const [browserInfo, setBrowserInfo] = useState<BrowserObject | undefined>();

  useEffect(() => {
    setBrowserInfo(detectBrowser());
  }, []);

  const toggleMenu = useCallback(() => {
    // menu打开时禁用页面滚动
    !menuVisible ? disableMobileScrolling() : enableMobileScrolling();
    setMenuVisible(!menuVisible);
  }, [menuVisible]);

  const menuContent = useMemo(
    () => (
      <ul className={styles.menu}>
        {(nav || []).map((item, index, arr) => (
          <li key={item.link ?? index}>
            {item.children &&
            item.children.length > 0 &&
            browserInfo?.isMobile ? (
              <ul className={styles.itemWithChildren}>
                <span className={styles.itemParentText}>{item.text}</span>
                {item.children.map((child) => (
                  <li key={child.link}>
                    <MenuItem
                      item={child}
                      isActive={isActive(router.asPath, child)}
                      onClick={toggleMenu}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <MenuItem
                item={item}
                isActive={isActive(router.asPath, item)}
                onClick={toggleMenu}
              />
            )}
            {index !== arr.length - 1 && <div className={styles.separator} />}
          </li>
        ))}
      </ul>
    ),
    [browserInfo, nav, router.asPath, toggleMenu],
  );

  return (
    <>
      <div className={styles.navWrap}>
        <div
          className={cn(
            'content',
            'row',
            'row-space-between',
            'row-middle',
            styles.navContainer,
          )}
        >
          <Link href="/">
            <a className="logo">
              <Logo className="row-start, logo" />
            </a>
          </Link>
          <div className={cn('row-end', styles.rightContainer)}>
            {navButton && (
              <Link href={formatRouterPath(navButton.link || '')}>
                <div className={styles.applyButtonMobile}>
                  {navButton.text || ''}
                </div>
              </Link>
            )}
            {nav?.length > 0 && (
              <div className={styles.mobileIconWrapper} onClick={toggleMenu}>
                {menuVisible ? <CloseSvg /> : <MenuSvg />}
              </div>
            )}
          </div>
          <div className={cn('row-end', styles.menuWrap)}>
            {menuContent}
            {navButton && (
              <Link href={formatRouterPath(navButton.link || '')}>
                <div className={styles.applyButtonPc}>
                  {navButton.text || ''}
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div
        className={cn(
          styles.menuWrap_mobile,
          menuVisible && styles.menuWrap_open,
        )}
      >
        {menuContent}
      </div>
    </>
  );
}

export default Navigator;
