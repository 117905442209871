import React, { useCallback, useMemo } from 'react';
import styles from './index.module.less';
import GooglePlayIcon from '@/assets/svg/googlePlay.svg';
import AppleStoreIcon from '@/assets/svg/appleStore.svg';
import HuaweiStoreIcon from '@/assets/svg/huaweiStore.svg';
import Link from 'next/dist/client/link';
import { formatRouterPath } from '@/utils/misc';
import { getPageImages } from '@/utils/resource';
import { Logo } from '@const/index';
import cn from 'classnames';
// import OJKIcon from '@/assets/image/OJK.png';
// import LPSIcon from '@/assets/image/LPS.png';

interface IProps {
  nav: NavItem[];
  disclaimer: string;
  copyright: string;
  downloadUrl?: DownloadUrl;
  followUrl?: FollowUrl;
}

export default function Footer({
  nav,
  disclaimer,
  copyright,
  downloadUrl: {
    downloadUrlForAndroid = '',
    downloadUrlForIos = '',
    downloadUrlForHuawei = '',
  } = {},
}: IProps) {
  const showDownload = useMemo(
    () => downloadUrlForAndroid && downloadUrlForIos && downloadUrlForHuawei,
    [downloadUrlForAndroid, downloadUrlForIos, downloadUrlForHuawei],
  );

  const createSubItem = useCallback(
    (item: NavItem) => {
      switch (item.type) {
        case 'market-list':
          return showDownload ? (
            <li className={styles.downloadLogo} key={item.type}>
              <Link href={downloadUrlForAndroid}>
                <a>
                  <GooglePlayIcon />
                </a>
              </Link>
              <Link href={downloadUrlForIos}>
                <a>
                  <AppleStoreIcon />
                </a>
              </Link>
              <Link href={downloadUrlForHuawei}>
                <a>
                  <HuaweiStoreIcon />
                </a>
              </Link>
            </li>
          ) : null;
        case 'icon-list':
          return (
            <li className={styles.followLogo} key={item.type}>
              {(item?.list || []).map(({ iconUrl, link }) => (
                <Link href={link} key={iconUrl}>
                  <img {...getPageImages(iconUrl)} />
                </Link>
              ))}
            </li>
          );
        default:
          return (
            <li key={item.text}>
              <Link href={formatRouterPath(item.link || '')}>
                <span>
                  <span>{item.text}</span>
                  {item.subText && (
                    <p className={cn('text-gray', styles.notes)}>
                      {item.subText}
                    </p>
                  )}
                </span>
              </Link>
            </li>
          );
      }
    },
    [
      showDownload,
      downloadUrlForAndroid,
      downloadUrlForIos,
      downloadUrlForHuawei,
    ],
  );

  return (
    <div className={styles.container}>
      <div
        className={cn('content', 'row', 'row-space-between', styles.content)}
      >
        <div className="col-md-order-2 col-md-10">
          <Logo className={cn('logo', styles.logo_mobile)} />
          <ul className={styles.list}>
            {(nav || []).map((item) => {
              const { span } = item;
              return (
                <li className={cn('col-12', `col-md-${span}`)} key={item.text}>
                  <span className="text-gray">{item.text}</span>
                  <ul className={styles.subList}>
                    {(item.children || []).map((sub) => {
                      return createSubItem(sub);
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-12 col-md-2 col-md-order-1">
          <Logo className={cn('logo', styles.logo_pc)} />
        </div>
      </div>
      {copyright && (
        <>
          <div className={styles.copyRightSeparator} />
          {disclaimer && (
            <pre className={cn('text-gray', styles.declaimer)}>
              {disclaimer}
            </pre>
          )}
          <div className={styles.copyright}>{copyright}</div>
        </>
      )}
    </div>
  );
}
