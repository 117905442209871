import React, { useState } from 'react';
import '@/styles/globals.less';
import '@/styles/grid.less';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Navigator from '@/components/Navigator';
import Footer from '@comp/Footer';
import TopNotification from '@comp/TopNotification';
import Sentry from '@/utils/sentry';
import { getSchemaNodes } from '@/utils/schema';
import { getMSiteHost } from '@/utils/resource';
import { getPageInfo } from '@/utils/misc';
import { COUNTRY } from '@const/index';
import '../init/day';

const host = getMSiteHost();

const shareImg = ({
  id: `${host}/assets/image/seabank-share.png`,
  ph: `${host}/assets/image/seabank-share.png`,
  sg: `${host}/assets/image/maribank-share.png`,
} as Record<string, string>)[COUNTRY];

const favicon = ({
  id: '/favicon.png',
  ph: '/favicon.png',
  sg: '/favicon-sg.png',
} as Record<string, string>)[COUNTRY];

function App({ Component, pageProps, router }: AppProps) {
  const { title, desc, path } = getPageInfo(router, pageProps);
  const [runtimeState, setRuntimeState] = useState<AppRuntimeState>({});
  const link =
    router.asPath === '/'
      ? host
      : `${host}${runtimeState?.customCanonicalPath || path}`;
  return (
    <Sentry.ErrorBoundary>
      <Head>
        <title>{runtimeState.title || title}</title>
        <meta name="description" content={runtimeState.description || desc} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={link} />
        <meta property="og:image" content={shareImg} />
        <meta
          property="og:description"
          content={runtimeState.description || desc}
        />
        <link rel="canonical" href={link} />
        <link rel="icon" href={favicon} type="image/png" />
        <meta
          httpEquiv="Cache-Control"
          content="no-cache, no-store, must-revalidate"
        />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Expires" content="0" />
        <base target="_blank" />
        {getSchemaNodes({
          route: router,
          props: pageProps,
          questions: runtimeState.questions,
        })}
      </Head>
      <div className="header-wrap">
        {process.env.NOTIFICATION_DISABLED !== 'true' ? (
          <TopNotification
            {...pageProps.template?.topNotice}
            title={pageProps.title}
            keyword={pageProps.keyword}
          />
        ) : null}
        {!(pageProps.hiddenHeader || runtimeState.hiddenHeader) && (
          <Navigator {...pageProps.template?.header} />
        )}
      </div>
      <div className="content-wrap">
        <Component {...pageProps} setRuntimeState={setRuntimeState} />
        {!(pageProps.hiddenFooter || runtimeState.hiddenFooter) && (
          <Footer {...pageProps.template?.footer} />
        )}
      </div>
    </Sentry.ErrorBoundary>
  );
}

export default App;
